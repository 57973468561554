import React from 'react'

export default function Total({ order }) {
  return (
    <table className=' w-100'>
      <tbody>

        <tr className='align-top'>
          <th>Sub-Total: </th>
          <td className='text-end'>{order.currency_symbol + (parseFloat(order.discount_total) + parseFloat(order.total)).toFixed(2)}</td>
        </tr>

        {
          order.coupon_lines.map((coupon, index) => {
            if (coupon.code === "cart discount") {
              {
                return coupon.meta_data.map((mc, index) =>
                  <tr key={index} className='align-top'>
                    <th>Coupon({mc.value.code}): </th>
                    <td className='text-end'>{"-" + order.currency_symbol + mc.value.amount}</td>
                  </tr>
                )
              }
            }
            else {
              return (
                <tr key={index} className='align-top'>
                  <th>Coupon({coupon.code}): </th>
                  <td className='text-end'>{"-" + order.currency_symbol + coupon.discount}</td>
                </tr>
              )
            }
          })
        }

        {/* <tr className='align-top'> */}
        {/* <th>Total Discount: </th> */}
        {/* <td>{order.currency_symbol + order.discount_total}</td> */}
        {/* </tr> */}

        {
          order.shipping_lines[0].method_title === "Delivery" &&
          <tr className='align-top'>
            <th>Delivery Charge: </th>
            <td className='text-end'>{order.currency_symbol + order.shipping_lines[0].total}</td>
          </tr>
        }

        <tr className='align-top'>
          <th>Total: </th>
          <td className='text-end'>{order.currency_symbol + order.total}</td>
        </tr>

        <tr className='align-top'>
          <th>Payment Method: </th>
          <td className='text-end'>{order.payment_method}</td>
        </tr>

      </tbody>
    </table>
  )
}
